@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-center font-semibold leading-7 my-8 text-foreground text-xl md:text-[22px] md:leading-[30px] md:mt-20 md:mb-10
  }
  h2 {
    @apply font-normal text-lg leading-[26px] text-center -tracking-[0.0024em] text-foreground mt-4 mb-8 md:mb-10 md:text-xl md:leading-7
  }
  h3 {
    @apply font-normal text-foreground text-center text-sm leading-[18px] mx-auto
  }
  p {
    @apply text-foreground
  }
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/SF-Pro-Text-Light.ttf');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/SF-Pro-Text-Medium.ttf');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/SF-Pro-Text-Regular.otf');
  font-display: swap;
}

/* * {
  font-family: system-ui, sans-serif;
  box-sizing: border-box;
} */

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html, body {
  height: 100%;
  font-family: 'SF Pro Text', system-ui, sans-serif;
}

body {
  line-height: 1;
}

:root {
  --reviews-io-widget-star-color: #EB731A;
  --reviews-io-widget-star-color-disabled: rgba(235, 115, 26, 0.5);
  --reviews-io-widget-header-star-color-disabled: rgba(32, 43, 71, 0.5);
  --reviews-io-widget-text-color: #010101;
  --reviews-io-widget-background-color: #FFFFFF;
}

ol, ul {
  list-style: none;
}

svg .weightGraphicPath {
  stroke-dasharray: 500;
  stroke-dashoffset: 0;
  animation: weightGraphicDashAnimation 2s both;
}

@keyframes weightGraphicDashAnimation {
  from {
    stroke-dashoffset: 500;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes opacityAnimation {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

/* @description: Hack for Paywall - for "OR" text */
div[class^="divider"] {
  font-family: 'SF Pro Text', serif;
}

/* Google address autocomplete dropdown */
.pac-container {
  background-color: #FAFAFA;
  font-family: 'SF Pro Text', -apple-system, sans-serif;
}
.pac-item,
.pac-item-query {
  color: #010101;
}
.pac-item:hover {
  background: #6666;
}
